import { Store } from 'redux';
import { Base64State, ActionTypes } from '../definitions';

export default function createDragAndDropEvents(
  inputTextarea: HTMLTextAreaElement,
  store: Store<Base64State, ActionTypes>
): void {
  const endDrag = () => {
    document.body.classList.remove('droppable');
  };

  let endDragTimeout: NodeJS.Timeout | void;

  document.addEventListener('dragover', evt => {
    document.body.classList.add('droppable');
    evt.preventDefault();

    if (endDragTimeout) {
      clearTimeout(endDragTimeout);
      endDragTimeout = undefined;
    }
    endDragTimeout = setTimeout(endDrag, 300);
  });

  document.addEventListener('drop', evt => {
    evt.preventDefault();

    if (endDragTimeout) {
      clearTimeout(endDragTimeout);
      endDragTimeout = undefined;
    }
    endDrag();

    if (!evt.dataTransfer) throw 'no data from event :(';

    const fileListing = Array.from(evt.dataTransfer.items).filter(
      file => file.kind === 'file'
    )[0];

    if (!fileListing) throw 'no viable file found :(';
    const file = fileListing.getAsFile();

    const reader = new FileReader();

    const whenFinished = (evt: ProgressEvent) => {
      if (!evt.target) throw 'no target for event :(';

      inputTextarea.value = evt.target.result;
      store.dispatch({
        type: 'TEXT_CHANGED',
        text: inputTextarea.value
      });
    };

    reader.addEventListener('loadend', whenFinished);
    if (!file) throw 'no file found :(';
    reader.readAsText(file);
  });
}
