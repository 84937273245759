import { getStore } from '../store';
import { ConverterDirections } from '../definitions';

const handleInitialText = (textarea: HTMLTextAreaElement) => {
  const initialText = extractUrlState();
  const store = getStore();

  if (initialText) {
    const decoded = decodeURIComponent(initialText)
    textarea.value = decoded;
    store.dispatch({
      type: 'TEXT_CHANGED',
      text: decoded
    });
  }
};

export { handleInitialText };

const extractUrlState: ()=>string|null = () => {
  const url = new URL(window.location.toString());

  const urlMatcher = url.pathname.match(/^\/(.+)$/);
  if( urlMatcher ) {
    return urlMatcher[1]
  }

  return null;
};
