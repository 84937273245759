import isTextBase64ish from './isTextBase64ish';

// TODO: add some support for encoding non-ASCII characters (and some way to message to the UI that these have been encoded as such)

//btoa(unescape(encodeURIComponent(str)))
const encode = (str: string) => {
  return btoa(str);
};

//decodeURIComponent(escape(window.atob(str)))
function decode(str: string, force = true): string | false {
  let output: string | false;

  try {
    output = atob(str);
  } catch (err) {
    return false;
  }

  return output;
}

export { encode, decode };
