import { decode } from './base64-converter';

const isTextBase64ish = (text = '', extendedChecks = false): boolean => {
  const base64matcher = /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?\n?$/;

  if (text === '') {
    // regex matches against blank strings, sidestep that
    return false;
  }

  const matchesBase64Regex = base64matcher.test(text);

  if (!matchesBase64Regex) return false;

  if (extendedChecks) {
    return extendedBase64Checks(text);
  }

  return true;
};

const testWithHeuristics = (text = '') => isTextBase64ish(text, true);

// this performs up to 4 tests...
// ...first, does the string end in 1–3 equals signs? If so assume it's Base64
// ...then run up to three tests, assume true if at least 2 of them pass…
//    (first truncating to 1000 chars because that should be enough for anyone)
//    1. are there a significant # of uncommon chars in the input ('Q', 'Z')
//    2. are there non-leading caps in the input
//    3. does the converted result have uncommon chars (just check for non-ascii for now)
// otherwise infer it's not Base64
const extendedBase64Checks = (text: string): boolean => {
  if (text.match(/[^=]={1,3}$/)) return true;

  let testText = text.substring(0, 1000);
  // are greater > 1% of chars a capital Q, X, or Z
  const hasUnusualChars =
    (testText.match(/[QXZ]/g) || []).length * 100 > testText.length;
  // are greater > 5% of non-leading chars capitals at all
  const hasInternalCaps =
    (testText.match(/[^\s][A-Z]/g) || []).length * 20 > testText.length;

  if (hasUnusualChars && hasInternalCaps) return true;
  if (!hasUnusualChars && !hasInternalCaps) return false;

  // when 'decoded' does the test string have > 10% non-ascii chars
  const mockEncodedString = decode(testText) as string;
  const hasUncommonDecoding =
    (mockEncodedString.match(/[^\x00-\x7F]/g) || []).length * 10 >
    testText.length;

  if (hasUncommonDecoding) return true;

  return false;
};

export default isTextBase64ish;
export { testWithHeuristics };
