import { createStore, Store } from 'redux';

import { Base64State, ActionTypes } from './definitions';

const initialState: Base64State = {
  text: '',
  converter: 'auto'
};

const textAndConverterReducer = (state = initialState, action: ActionTypes) => {
  switch (action.type) {
    case 'TEXT_CHANGED': {
      return { ...state, text: action.text };
    }
    case 'CONVERTER_CHANGED': {
      return { ...state, text: action.text ?? state.text, converter: action.converter };
    }
    default: {
      return state;
    }
  }
};

const includeDevTools = window.__REDUX_DEVTOOLS_EXTENSION__;

let store: Store<Base64State, ActionTypes>;

const getStore = () => {
  if (store) return store;

  store = createStore(
    textAndConverterReducer,
    includeDevTools ? window.__REDUX_DEVTOOLS_EXTENSION__() : undefined
  );

  return store;
};

export { getStore };
