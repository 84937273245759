import * as base64converter from './utils/base64-converter';
import isTextBase64ish from './utils/isTextBase64ish';
import { getStore } from './store';
import createDragAndDropEvents from './components/drag-and-drop';
import { handleInitialText } from './utils/parse-and-dispatch-initial-text';
import randFace from './utils/random-emoji-face';
import registerServiceWorker from './components/register-service-worker';

import { ConverterDirections } from './definitions';

registerServiceWorker();

const inputTextarea = <HTMLTextAreaElement>document.querySelector('#input');
const checkboxes = <HTMLFormElement[]>(
  Array.from(document.querySelectorAll('input[name="convert-type"]'))
);
const copyButton = <HTMLFormElement>document.querySelector('#copy-bug');
const outputArea = <HTMLFormElement>document.querySelector('#output');
const outputAreaErrorMessage = <HTMLElement>(
  document.querySelector('.output-container .failed-convert-note')
);

const handleConversion = () => {
  const { text, converter } = store.getState();

  const conversionFunction = getConversionFunction(converter);
  const convertedContents = text === '' ? '' : conversionFunction(text);

  if (typeof convertedContents !== 'boolean') {
    outputAreaErrorMessage.hidden = true;
    outputArea.innerText = convertedContents;
  } else if (convertedContents === false) {
    outputAreaErrorMessage.hidden = false;
    outputArea.innerText = '';
  }
};

const store = getStore();
// expose store globally for convenient poking around
window.store = store;
store.subscribe(handleConversion);

const getConversionFunction = (converter: ConverterDirections) => {
  if (converter === 'auto') {
    converter = isTextBase64ish(store.getState().text)
      ? 'from-base64'
      : 'to-base64';
  }

  const transformFunctionMap = {
    'from-base64': base64converter.decode,
    'to-base64': base64converter.encode
  };

  return transformFunctionMap[converter];
};

const handleDirectionChange = (evt: Event) => {
  const newConverter = <ConverterDirections>(
    (<HTMLInputElement>evt.target).value
  );
  const { converter: oldConverter } = store.getState();

  const checkedElement = <HTMLFormElement>(
    document.querySelector('input[name="convert-type"]:checked')
  );

  // if moving from explicit-text to explicit-base64, swap contents
  let newText;
  if (
    oldConverter !== newConverter &&
    ![oldConverter, newConverter].includes('auto')
  ) {
    newText = outputArea.innerText;
    inputTextarea.value = newText;
  }

  store.dispatch({
    type: 'CONVERTER_CHANGED',
    converter: newConverter,
    text: newText || inputTextarea.value
  });
};

const handleTextChange = () => {
  const newText = inputTextarea.value;

  store.dispatch({
    type: 'TEXT_CHANGED',
    text: newText
  });
};

checkboxes.forEach(ele => {
  ele.addEventListener('change', handleDirectionChange);
});

inputTextarea.addEventListener('keypress', handleTextChange);
document.addEventListener('keyup', handleTextChange);

copyButton.addEventListener('click', evt => {
  copyButton.classList.add("just-added")
  copyButton.innerHTML = `Sure, copied! ${randFace()}`
  navigator.clipboard.writeText(outputArea.innerText);
  setTimeout(() => {
    copyButton.classList.remove("just-added")
    copyButton.innerHTML = "Copy"  
  }, 700)
});

handleInitialText(inputTextarea);
createDragAndDropEvents(inputTextarea, store);
